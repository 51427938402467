import React, { useState, useCallback, memo, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { UploadAttachment, Button } from '@chhjit/react-components';

import { ATTACHMENT_MAX_SIZE } from 'common/utils/env-variables';
import { FileObj } from 'common/utils/types/attachments';

import { useStyles } from './UploadAttachmentDialog.styles';
import { UploadAttachmentDialogProps } from './UploadAttachmentDialog.types';

export const UploadAttachmentDialog = memo(({ onClose, uploadAttachment, limit }: UploadAttachmentDialogProps) => {
  const styles = useStyles();

  const [description, setDescription] = useState<string>('');
  const [selectedFiles, setSelectedFiles] = useState<FileObj[]>([]);
  const [uploading, setUploading] = useState(false);

  const onAdd = useCallback(
    (file: File) => {
      setSelectedFiles(
        selectedFiles.concat({
          id: Number(new Date().getTime()),
          url: URL.createObjectURL(file),
          file,
          name: file.name,
          description,
        }),
      );
      setDescription('');
    },
    [description, selectedFiles],
  );

  const removeFile = useCallback(
    (id: number) => {
      setSelectedFiles(selectedFiles.filter((obj) => obj.id !== id));
    },
    [selectedFiles],
  );

  const onUpload = useCallback(async () => {
    if (selectedFiles.length) {
      setUploading(true);
      await uploadAttachment(selectedFiles);
      onClose();
      setUploading(false);
    }
  }, [onClose, selectedFiles, uploadAttachment]);

  const formAttachments = useMemo(
    () =>
      selectedFiles.map((fileObj) => ({
        id: fileObj.id,
        name: fileObj.file.name,
        description: fileObj.description,
        url: fileObj.url,
      })),
    [selectedFiles],
  );

  return (
    <div>
      <Grid container className={styles.container}>
        <div className={styles.wrapper}>
          <UploadAttachment
            attachments={formAttachments}
            amountLimit={limit}
            description={description}
            changeDescription={setDescription}
            selectedFile={null}
            changeSelectedFile={(file) => file && file instanceof File && onAdd(file)}
            acceptFormats="image/jpeg, image/jpg, image/png, .pdf, .doc, .docx"
            fileSizeValidation={{
              maxFileSizeKb: ATTACHMENT_MAX_SIZE,
              showAlert: true,
            }}
            onDeleteAttachment={removeFile}
          />

          <Grid className={styles.buttonsContainer} container justify="flex-end" spacing={1}>
            <Grid item>
              <Button buttonType="text" color="primary" onClick={onClose} disabled={uploading}>
                Cancel
              </Button>
            </Grid>

            <Grid item>
              <Button
                buttonType="twoTone"
                className={styles.confirmButton}
                onClick={onUpload}
                disabled={!selectedFiles.length}
                isLoading={uploading}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </div>
  );
});
