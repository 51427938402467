import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    maxWidth: '456px',
  },
  wrapper: {
    width: '456px',
  },
  confirmButton: {
    width: '136px',
    '& .MuiButtonBase-root': {
      width: '100%',
    },
  },
  buttonsContainer: {
    marginTop: '32px',
  },
}));
