import React, { useMemo, useState, useCallback } from 'react';
import { Typography, Box } from '@material-ui/core';
import { Button, Loader } from '@chhjit/react-components';
import { useHistory, Redirect } from 'react-router-dom';

import { junkRemovalRoutes } from 'common/routing/AppRoute';
import { ButtonsGroup } from 'common/ui/buttonsGroup/ButtonsGroup';
import { TipBox } from 'common/ui/tipBox/TipBox';
import { useFormDispatch } from 'common/hooks/useFormDispatch/useFormDispatch';
import { setReadyToProcessing } from 'common/context/form/formActionCreators/formActionCreators';
import { ReadyForRequestProcessingEnum } from 'common/context/form/formContext/FormContext.types';
import { useJunkRemovalStateContext } from 'common/hooks/useJunkRemoval/useJunkRemoval';
import { useVersionContext } from 'common/hooks/useVersion/useVersion';
import { WeWillTextModalContainer } from 'common/app/junkRemoval/weWillTextModal/WeWillTextModalContainer';
import { useFormState } from 'common/hooks/useFormState/useFormState';
import { PARTNER_NAME } from 'common/utils/env-variables';

import { useStyles } from './JunkRemovalStep5.styles';
import { JunkRemovalStep5Item } from './junkRemovalStep5Item/JunkRemovalStep5Item';
import { pricingsData, pricingsDataForPartners, getDiscountData } from './JunkRemovalStep5.utils';
import { JunkRemovalStep5ItemForPartners } from './junkRemovalStep5ItemForPartners/JunkRemovalStep5ItemForPartners';

export const JunkRemovalStep5 = () => {
  const styles = useStyles();
  const history = useHistory();
  const { junkRemoval, pricing, isGettingPricing } = useJunkRemovalStateContext();
  const { isPartner, partnerData } = useFormState();
  const dispatch = useFormDispatch();
  const { junkVer } = useVersionContext();
  const [dialogIsOpen, setDialog] = useState(false);

  const openDialog = useCallback(() => setDialog(true), []);
  const closeDialog = useCallback(() => setDialog(false), []);

  const discount = useMemo(() => {
    if (!isPartner || !partnerData?.discounts) {
      return;
    }

    return getDiscountData(partnerData.discounts);
  }, [partnerData?.discounts, isPartner]);

  const openNextStep = useCallback(() => {
    dispatch(setReadyToProcessing({ readyForRequestProcessing: ReadyForRequestProcessingEnum.JunkRemoval }));
    history.push(junkRemovalRoutes.processing);
  }, [dispatch, history]);

  const handleNextClick = useCallback(() => {
    if (junkVer === 'A') {
      openNextStep();
    } else {
      openDialog();
    }
  }, [openNextStep, junkVer, openDialog]);

  const isPlaceForMom = useMemo(() => partnerData?.partnerName === PARTNER_NAME, [partnerData?.partnerName]);

  if (!junkRemoval?.step4) {
    return <Redirect to={junkRemovalRoutes.step4} />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <Typography variant="h4">5. Pricing</Typography>

        <Box mt={2} />
        <Typography variant="body1">
          We have 13 different price points, however, everyone's junk is unique! Below are 3 of our 13 different price
          points to give you an idea of what the {isPlaceForMom ? 'downsizing support' : 'junk removal'} will cost you.
          Once on-site, <strong>our team will provide you a free no-obligation price quote on your exact items</strong>.
          See below for 3 example price points for our locally owned and operated location servicing your area.
        </Typography>

        {isGettingPricing && (
          <div className={styles.loaderContainer}>
            <Loader />
          </div>
        )}
        {!isGettingPricing && !isPartner && (
          <div className={styles.itemsContainer}>
            {pricingsData.map(({ title, imgSrc }) => (
              <JunkRemovalStep5Item key={title} title={title} imgSrc={imgSrc} price={pricing[title]} />
            ))}
          </div>
        )}

        {!isGettingPricing && isPartner && (
          <div className={styles.itemsForPartnerContainer}>
            {pricingsDataForPartners.map(({ title, imgSrc }) => (
              <JunkRemovalStep5ItemForPartners
                key={title}
                title={title}
                imgSrc={imgSrc}
                price={pricing[title]}
                discountData={discount}
              />
            ))}
          </div>
        )}

        <TipBox iconName="junkRemoval">
          Did you know that our trucks are <strong>10% larger</strong> on average than our competitors?
        </TipBox>
      </div>

      <ButtonsGroup>
        <Button onClick={() => history.push(junkRemovalRoutes.step4)} fullWidth size="large" buttonType="outlined">
          Back
        </Button>
        <Button onClick={handleNextClick} isLoading={isGettingPricing} fullWidth size="large" buttonType="twoTone">
          {/* TODO SUMMARY: commented summary step in junk removal for now */}
          Great, book now!
        </Button>
      </ButtonsGroup>

      {junkVer === 'B' && (
        <WeWillTextModalContainer open={dialogIsOpen} onClose={closeDialog} onSubmit={openNextStep} />
      )}
    </div>
  );
};
