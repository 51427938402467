import { colors } from '@chhjit/react-components';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    maxWidth: 489,
  },
  tipBox: {
    maxWidth: 489,
  },
  margin: {
    marginBottom: 16,
  },
  divider: {
    borderBottom: `3px dashed ${colors.grey40}`,
    margin: '16px 0 24px',
  },
  attachmentBox: {
    borderTop: `1px solid ${colors.grey30}`,
    marginTop: 8,
    paddingTop: 32,
  },
  fileTypes: {
    fontSize: 12,
    color: colors.grey140,
    marginTop: 4,
    fontWeight: 500,
  },
}));
