import React, { useMemo } from 'react';
import { ClientContextProvider, createClient } from 'react-fetching-library';
import { useAlert } from '@chhjit/react-components';

import { requestHostInterceptor } from 'common/api/interceptors/requestHostInterceptor/requestHostInterceptor';
import { responseErrorInterceptor } from 'common/api/interceptors/responseErrorInterceptor/responseErrorInterceptor';
import { API_URL } from 'common/utils/env-variables';

import { ClientProviderProps } from './ClientContextController.types';

export const ClientContextController = ({ children }: ClientProviderProps) => {
  const { showAlert } = useAlert();

  const client = useMemo(() => {
    return createClient({
      requestInterceptors: [requestHostInterceptor(API_URL)],
      responseInterceptors: [responseErrorInterceptor(showAlert)],
    });
  }, [showAlert]);

  return <ClientContextProvider client={client}>{children}</ClientContextProvider>;
};
