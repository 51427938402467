import { useMutation } from 'react-fetching-library';
import { useCallback } from 'react';
import { useAlert } from '@chhjit/react-components';

import { uploadAction } from 'common/api/actions/attachments/AttachmentsActions';
import { Attachment, FileObj } from 'common/utils/types/attachments';
import { fileToBase64 } from 'common/utils/attachments';

import { useFeatureFlags } from './use-feature-flags/use-feature-flags';

export const useUploadAttachemnt = (attachments: Attachment[], onUpload: (attachments: Attachment[]) => void) => {
  const { showAlert } = useAlert();
  const { toggles } = useFeatureFlags();
  const { mutate: uploadQuery } = useMutation(uploadAction);

  const upload = useCallback(
    async (files: FileObj[]) => {
      if (toggles.PartnerPortal_AddAttachments && files.length) {
        const newAttachments: Attachment[] = attachments;

        for (const { file, description } of files) {
          try {
            const { payload } = await uploadQuery({
              type: {
                id: 3,
              },
              description,
              content: (await fileToBase64(file)) as string,
              extension: file.name.split('.').pop() || '',
            });

            if (payload) {
              newAttachments.push({
                id: Number(new Date().getTime()),
                name: file.name,
                description,
                url: payload.bucket_url,
              });
            }
          } catch (error) {
            showAlert(`Falied to attach file: "${file.name}"`, {
              variant: 'error',
            });
          }
        }

        onUpload(newAttachments);
      }
    },
    [attachments, onUpload, showAlert, toggles.PartnerPortal_AddAttachments, uploadQuery],
  );

  return { upload };
};
