import { Action } from '../../types';

import { AttachmentPayload, UploadAttachmentAction, UploadResponse } from './AttachmentsActions.types';

export const uploadAttachmentAction = ({
  locationId,
  appointmentId,
  payload,
  token,
}: UploadAttachmentAction.Params): Action<UploadAttachmentAction.Response> => {
  return {
    method: 'POST',
    endpoint: `/public/website/locations/${locationId}/appointments/${appointmentId}/attachments?token=${token}`,
    body: payload,
  };
};

export const uploadAction = (body: AttachmentPayload): Action<UploadResponse> => {
  return {
    method: 'POST',
    endpoint: `/public/website/upload`,
    body,
  };
};
