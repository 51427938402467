import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-fetching-library';
import { TimePickerSchedule, useAlert } from '@chhjit/react-components';
import moment from 'moment';

import { rudderanalytics } from 'common/utils/rudderanalytics';
import { useFormState } from 'common/hooks/useFormState/useFormState';
import {
  createNewQuoteAction,
  createAppointmentAction,
  createAccountAddressAction,
  createCallRequestAction,
} from 'common/api/actions/account/accountActions';
import { useFormDispatch } from 'common/hooks/useFormDispatch/useFormDispatch';
import { setBookingDoneAndContact, setGlobalData } from 'common/context/form/formActionCreators/formActionCreators';
import { movingRoutes } from 'bMove/routing/AppRoute';
import { ProcessingRequest } from 'common/app/processingRequest/ProcessingRequest';
import { CategoryIdEnum } from 'common/api/types';
import { mapTruckParkingToLongWalks } from 'common/utils/form';
import { DateFormatForAPI } from 'common/utils/dateTime';
import { useAvailability } from 'common/hooks/useAvailability/useAvailability';
import { useMovingDispatchContext, useMovingStateContext } from 'bMove/hooks/useMoving/useMoving';
import { movingActions } from 'bMove/context/moving/MovingContextSlice';
import { useFeatureFlags } from 'common/hooks/use-feature-flags/use-feature-flags';
import { uploadAttachmentAction } from 'common/api/actions/attachments/AttachmentsActions';

export const MovingProcessingRequest = () => {
  const history = useHistory();

  const {
    locationId,
    accountId,
    token,
    readyForRequestProcessing,
    isCallRequired,
    appointmentId,
    partnerData,
  } = useFormState();
  const { moving, jobDuration, prepaymentData } = useMovingStateContext();

  const dispatch = useFormDispatch();
  const { movingDispatch } = useMovingDispatchContext();

  const { mutate: createQuoteMutate, abort: createQuoteAbort } = useMutation(createNewQuoteAction);
  const { mutate: createAddressMutate, abort: abortCreateAddress } = useMutation(createAccountAddressAction);
  const { mutate: createAppointmentMutate, abort: abortCreateAppointment } = useMutation(createAppointmentAction);
  const { abort: abortCreateCallRequest, mutate: createCallRequestMutate } = useMutation(createCallRequestAction);
  const { mutate: uploadAttachmentMutate } = useMutation(uploadAttachmentAction);

  const { toggles } = useFeatureFlags();
  const { showAlert } = useAlert();
  const { getTheSameOrSimilarSchedule } = useAvailability();

  const handleError = useCallback(
    (type: 'quote' | 'appointment') => {
      showAlert(`We were unable to create a ${type} for your moving, please contact us for information.`, {
        variant: 'error',
        autoHideDuration: null,
      });
    },
    [showAlert],
  );

  const createQuote = useCallback(async () => {
    if (
      !moving?.step1 ||
      !moving?.step2 ||
      !moving?.step3 ||
      !moving?.step4 ||
      !moving?.step5 ||
      !moving?.step7 ||
      !locationId ||
      !accountId ||
      !token
    ) {
      return;
    }

    createQuoteAbort();

    const { payload: createQuotePayload } = await createQuoteMutate({
      locationId,
      accountId,
      token,
      payload: {
        hunks: moving.step7.numberOfHunks,
        email_client: false,
        rooms: moving.step1.rooms,
        appliances: moving.step3.appliances,
        assembly_items: moving.step3.assemblyItems,
        heavy_items: moving.step3.heavyItems,
        date: moment(moving.step4.date).format(DateFormatForAPI),
        location: {
          id: locationId,
        },
        account: {
          id: accountId,
          first_name: moving.step5.firstName,
          last_name: moving.step5.lastName,
          email: moving.step5.email,
          phone: moving.step5.phone,
        },
        origin: {
          ...moving.step1.fullAddress,
          floor: moving.step1.floor ?? 0,
          access_type: moving.step1.accessType,
          long_walks: mapTruckParkingToLongWalks(moving.step1.truckParking),
        },
        destination: {
          ...moving.step2.fullAddress,
          floor: moving.step1.floor ?? 0,
          access_type: moving.step2.accessType,
          long_walks: mapTruckParkingToLongWalks(moving.step2.truckParking),
        },
        category: {
          id: CategoryIdEnum.move,
        },
        type: {
          id: 1,
        },
      },
    });

    if (createQuotePayload?.meta.status.code === 200) {
      return true;
    } else {
      return false;
    }
  }, [createQuoteAbort, createQuoteMutate, moving, locationId, accountId, token]);

  const getSchedule = useCallback(async () => {
    if (!moving?.step1 || !moving?.step4 || !moving?.step6 || !jobDuration) {
      return;
    }

    const schedule = await getTheSameOrSimilarSchedule({
      availabilityParams: {
        postal: moving.step1.fullAddress.postal,
        categoryId: CategoryIdEnum.move,
        jobDate: moving.step4.date,
        jobDuration,
      },
      currentSchedule: moving.step6.schedule,
    });

    if (!schedule) {
      showAlert('The selected time is not available, please select another time.', {
        variant: 'error',
      });

      movingDispatch(movingActions.resetStep({ step: 'step6' }));
      movingDispatch(movingActions.resetStep({ step: 'step8' }));

      history.push(movingRoutes.step6);
      return;
    }

    return schedule;
  }, [
    getTheSameOrSimilarSchedule,
    movingDispatch,
    showAlert,
    moving?.step1,
    moving?.step4,
    moving?.step6,
    jobDuration,
    history,
  ]);

  const createAppointment = useCallback(
    async (chosenSchedule?: TimePickerSchedule) => {
      if (
        !moving?.step1 ||
        !moving?.step2 ||
        !moving?.step3 ||
        !moving?.step4 ||
        !moving?.step5 ||
        !moving?.step6 ||
        !locationId ||
        !accountId ||
        !readyForRequestProcessing ||
        !token ||
        !jobDuration ||
        !chosenSchedule
      )
        return;

      abortCreateAddress();
      const { payload } = await createAddressMutate({
        locationId,
        accountId,
        token,
        fullAddress: moving.step1.fullAddress,
      });

      if (!payload?.addresses) return;

      const originId = payload.addresses[0].id;

      abortCreateAppointment();

      const { payload: appointmentPayload } = await createAppointmentMutate({
        locationId,
        token,
        start_date: chosenSchedule.start,
        end_date: chosenSchedule.end,
        category: { id: CategoryIdEnum.move },
        account: { id: accountId },
        origin: { id: originId },
        create_schedule: true,
        resource: { id: chosenSchedule.resourceId },
        purchase_order: moving.step5.orderNumber,
        notes: {
          special_instructions: moving.step5.specialInstructions,
          type_of_junk: moving.step5.additionalNotes ? `Additional notes: ${moving.step5.additionalNotes}` : '',
          location_of_junk: moving.step5.locationOfJunk || '',
        },
        ...(toggles.PartnerManagement_DefaultReferralSource &&
          partnerData?.source_id && {
            source: {
              id: partnerData.source_id,
            },
          }),
        ...(partnerData?.subpartnerId !== undefined && { partner: { id: partnerData.subpartnerId } }),
      });

      if (appointmentPayload?.meta.status.code === 200 && appointmentPayload?.appointments?.length) {
        dispatch(setGlobalData({ appointmentId: appointmentPayload.appointments[0].id }));
        rudderanalytics.track(`Appointment created!`);

        return true;
      } else {
        return false;
      }
    },
    [
      moving.step1,
      moving?.step2,
      moving?.step3,
      moving?.step4,
      moving.step5,
      moving?.step6,
      locationId,
      accountId,
      readyForRequestProcessing,
      token,
      jobDuration,
      abortCreateAddress,
      createAddressMutate,
      abortCreateAppointment,
      createAppointmentMutate,
      toggles.PartnerManagement_DefaultReferralSource,
      partnerData?.source_id,
      partnerData?.subpartnerId,
      dispatch,
    ],
  );

  const handleCallRequired = useCallback(async () => {
    if (!moving?.step5 || !locationId || !accountId || !token) {
      return;
    }

    abortCreateCallRequest();
    const { payload } = await createCallRequestMutate({
      locationId,
      accountId,
      token,
      category: { id: CategoryIdEnum.move },
      ...(partnerData ? { subpartner: { id: partnerData.subpartnerId } } : {}),
    });

    if (!payload?.requests) return;

    dispatch(
      setBookingDoneAndContact({
        type: 'phone',
        contact: moving.step5.phone,
      }),
    );
    history.push(movingRoutes.alldone);
  }, [
    createCallRequestMutate,
    abortCreateCallRequest,
    history,
    dispatch,
    moving?.step5,
    token,
    locationId,
    accountId,
    partnerData,
  ]);

  const redirectAfterProcess = useCallback(() => {
    if (!moving?.step5) {
      return;
    }
    // if prepayment cannot be collected, then do not make prepayment, go to allDone
    if (prepaymentData?.isPrepaymentAvailable) {
      history.push(movingRoutes.prepayment);
    } else {
      dispatch(
        setBookingDoneAndContact({
          type: 'phone',
          contact: moving.step5.phone,
        }),
      );
      history.push(movingRoutes.alldone);
    }
  }, [prepaymentData?.isPrepaymentAvailable, dispatch, history, moving?.step5]);

  const process = useCallback(async () => {
    if (isCallRequired) {
      handleCallRequired();
      return;
    }

    // if appointment is already created, then break the function and redirect
    if (appointmentId !== undefined) {
      redirectAfterProcess();
      return;
    }

    const chosenSchedule = await getSchedule();
    if (!chosenSchedule) {
      return;
    }

    const isQuoteCreated = await createQuote();
    if (!isQuoteCreated) {
      handleError('quote');
      return;
    }

    const isAppointmentCreated = await createAppointment(chosenSchedule);
    if (!isAppointmentCreated) {
      handleError('appointment');
      return;
    }

    redirectAfterProcess();
  }, [
    appointmentId,
    isCallRequired,
    createQuote,
    createAppointment,
    handleError,
    handleCallRequired,
    getSchedule,
    redirectAfterProcess,
  ]);

  return <ProcessingRequest onProcess={process} />;
};
