import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { AppRoute, junkRemovalRoutes } from 'common/routing/AppRoute';
import { useJunkRemovalContext } from 'common/hooks/useJunkRemoval/useJunkRemoval';
import { junkRemovalActions } from 'common/context/junkRemoval/JunkRemovalContextSlice';
import { CategoryIdEnum } from 'common/api/types';
import { ContactInformationForm } from 'common/app/contactInformationForm/ContactInformationForm';
import { ContactInformationFormValues } from 'common/app/contactInformationForm/ContactInformationForm.types';
import { useVersionContext } from 'common/hooks/useVersion/useVersion';
import { useAccount } from 'common/hooks/useAccount/useAccount';
import { useFormState } from 'common/hooks/useFormState/useFormState';
import { useCheckPostalCode } from 'common/hooks/useCheckPostalCode/useCheckPostalCode';
import { AccountResponse } from 'common/api/actions/account/accountActions.types';

export const JunkRemovalStep2 = () => {
  const history = useHistory();
  const [{ junkRemoval }, { junkRemovalDispatch }] = useJunkRemovalContext();
  const { createOrUpdateAccount, isCreatingOrUpdatingAccount, recreateAccountWithNewLocationId } = useAccount();
  const { junkVer } = useVersionContext();
  const { account } = useFormState();

  const { checkPostalCode, isChecking } = useCheckPostalCode({
    category_id: CategoryIdEnum.junk,
    need_postal_info: true,
  });

  const handleSubmit = useCallback(
    async (values: ContactInformationFormValues, isDirty) => {
      if (!isDirty) {
        history.push(junkRemovalRoutes.step1);
        return;
      }

      const { address, nearestLocationId } = await checkPostalCode(values.postal);

      let accountRes: AccountResponse | undefined;
      const payload = {
        contactInformationData: values,
        billingAddress: address,
        categoryId: CategoryIdEnum.junk,
      };

      if (account?.locationId && account.locationId !== nearestLocationId) {
        accountRes = await recreateAccountWithNewLocationId(payload, nearestLocationId);
      } else {
        accountRes = await createOrUpdateAccount(payload, nearestLocationId);
      }

      if (!accountRes) {
        return;
      }

      junkRemovalDispatch(junkRemovalActions.setStep2(values));
      history.push(junkRemovalRoutes.step1);
    },
    [
      checkPostalCode,
      account?.locationId,
      junkRemovalDispatch,
      history,
      recreateAccountWithNewLocationId,
      createOrUpdateAccount,
    ],
  );

  return (
    <ContactInformationForm
      loading={isCreatingOrUpdatingAccount || isChecking}
      onSubmit={handleSubmit}
      title="1. Contact information"
      onBackClick={() => history.push(AppRoute.home)}
      defaultValues={{
        firstName: junkRemoval?.step2?.firstName || account?.firstName || '',
        lastName: junkRemoval?.step2?.lastName || account?.lastName || '',
        email: junkRemoval?.step2?.email || account?.email || '',
        phone: junkVer === 'A' ? junkRemoval?.step2?.phone || account?.phone || '' : undefined,
        postal: junkRemoval?.step2?.postal || account?.postal,
        companyName: junkRemoval?.step2?.companyName || '',
        orderNumber: junkRemoval?.step2?.orderNumber || '',
        specialInstructions: junkRemoval?.step2?.specialInstructions || '',
        additionalNotes: junkRemoval?.step2?.additionalNotes || '',
        locationOfJunk: junkRemoval?.step2?.locationOfJunk || '',
        attachments: junkRemoval?.step2?.attachments || [],
      }}
      hasPrefilledData={!!junkRemoval?.step2}
      tipBoxData={{
        icon: 'threeStars',
        text: <>Our team will text/call you on the day of your service to let you know we are on the way.</>,
      }}
      categoryId={CategoryIdEnum.junk}
    />
  );
};
