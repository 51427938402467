import React, { ReactNode, useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import qs from 'query-string';
import { useMutation } from 'react-fetching-library';
import { Loader } from '@chhjit/react-components';
import { Box } from '@material-ui/core';

import { AppRoute } from 'common/routing/AppRoute';
import { useFormDispatch } from 'common/hooks/useFormDispatch/useFormDispatch';
import { setPartnerData } from '../form/formActionCreators/formActionCreators';
import { getPartnerInfoAction } from 'common/api/actions/partner/partnerActions';
import { CategoryIdEnum } from 'common/api/types';
import { useCheckPostalCode } from 'common/hooks/useCheckPostalCode/useCheckPostalCode';
import { useAccount } from 'common/hooks/useAccount/useAccount';
import { PartnerDataContextType } from '../form/formContext/FormContext.types';
import { MapStateToAccountParams } from 'common/hooks/useAccount/useAccount.utils';
import { formatPhoneForApi } from 'common/utils/form';

import { VersionContext } from './VersionContext';
import { VersionContextValue, UrlParams, Version } from './VersionContext.types';
import { versionStorage, versionsDefaultState } from './VersionStorage';
import { mapPartnerResponseToStateData } from './VersionContextController.utils';

export const VersionContextController = ({ children }: { children: ReactNode }) => {
  const { location, push } = useHistory();

  const formDispatch = useFormDispatch();

  const { mutate: getPartnerInfoMutate, abort: abortGetPartnerInfo, loading: isGettingPartnerInfo } = useMutation(
    getPartnerInfoAction,
  );

  const [servicesVersion, setServicesVersion] = useState(versionStorage.state);

  const { isCreatingOrUpdatingAccount, createOrUpdateAccount } = useAccount();
  const { isChecking, checkPostalCode } = useCheckPostalCode({
    category_id: CategoryIdEnum.move,
    need_postal_info: true,
  });

  const isLoading = useMemo(() => isGettingPartnerInfo || isCreatingOrUpdatingAccount || isChecking, [
    isGettingPartnerInfo,
    isCreatingOrUpdatingAccount,
    isChecking,
  ]);

  const getPartnerInfo = useCallback(
    async (partnerToken: string) => {
      abortGetPartnerInfo();

      const { payload } = await getPartnerInfoMutate({ subpartner_token: partnerToken });

      if (!payload?.subpartners?.length || payload.meta.errors?.length) {
        return;
      }

      const partnerData = mapPartnerResponseToStateData(payload.subpartners[0], partnerToken);

      formDispatch(setPartnerData(partnerData));

      return partnerData;
    },
    [getPartnerInfoMutate, abortGetPartnerInfo, formDispatch],
  );

  const getService = useCallback((categoryId?: string) => {
    if (Number(categoryId) === CategoryIdEnum.junk) {
      return { categoryId: CategoryIdEnum.junk, path: AppRoute.junkRemoval };
    }

    if (Number(categoryId) === CategoryIdEnum.labor) {
      return { categoryId: CategoryIdEnum.labor, path: AppRoute.labor };
    }

    return { categoryId: CategoryIdEnum.move, path: AppRoute.moving };
  }, []);

  const checkUrlParams = useCallback(
    async (urlParams: Partial<UrlParams>) => {
      const hasNecessaryParam = ([
        'junkVer',
        'moveVer',
        'partnerToken',
        'firstName',
        'lastName',
        'email',
        'phoneNumber',
        'postal',
      ] as (keyof UrlParams)[]).some((param) => urlParams[param]);

      if (hasNecessaryParam) {
        let partnerData: PartnerDataContextType | undefined;

        if (urlParams.junkVer || urlParams.moveVer) {
          const urlJunkVer = urlParams.junkVer?.toUpperCase() || '';
          const urlMoveVer = urlParams.moveVer?.toUpperCase() || '';

          const servicesVersion: VersionContextValue = {
            junkVer: ['A', 'B'].includes(urlJunkVer) ? (urlJunkVer as Version) : versionsDefaultState.junkVer,
            moveVer: ['A', 'B'].includes(urlMoveVer) ? (urlMoveVer as Version) : versionsDefaultState.moveVer,
          };

          setServicesVersion(servicesVersion);
          versionStorage.state = servicesVersion;
        }

        if (urlParams.partnerToken) {
          partnerData = await getPartnerInfo(urlParams.partnerToken);
        }

        if (urlParams.firstName && urlParams.lastName && urlParams.phoneNumber && urlParams.postal) {
          const service = getService(urlParams.categoryId);

          const { address, nearestLocationId } = await checkPostalCode(urlParams.postal, service.categoryId);

          const accountParams: MapStateToAccountParams = {
            contactInformationData: {
              firstName: urlParams.firstName || '',
              lastName: urlParams.lastName || '',
              email: urlParams.email || '',
              phone: urlParams.phoneNumber ? formatPhoneForApi(urlParams.phoneNumber) : '',
              postal: urlParams.postal || '',
              hasCorporateCode: !!partnerData,
              companyName: '',
              orderNumber: '',
              specialInstructions: '',
              additionalNotes: '',
              attachments: [],
            },
            billingAddress: address,
            categoryId: service.categoryId,
          };

          const account = await createOrUpdateAccount(accountParams, nearestLocationId, partnerData, true);

          if (account && urlParams.categoryId) {
            return push(service.path);
          }
        }

        const url = qs.stringifyUrl(
          { url: AppRoute.home, query: urlParams },
          {
            encode: false,
          },
        );

        return push(url);
      }
    },
    [checkPostalCode, createOrUpdateAccount, getPartnerInfo, getService, push],
  );

  useEffect(() => {
    const urlParams = qs.parse(location.search) as Partial<UrlParams>;

    checkUrlParams(urlParams);
  }, [location.search]);

  if (isLoading) {
    return (
      <Box height="100vh">
        <Loader />
      </Box>
    );
  }

  return <VersionContext.Provider value={servicesVersion}>{children}</VersionContext.Provider>;
};
