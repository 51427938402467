import React, { useCallback } from 'react';
import { Typography } from '@material-ui/core';
import { AttachmentItem, Button, useConfirmationDialog, useDialogDispatch } from '@chhjit/react-components';
import { useController } from 'react-hook-form';

import { UploadAttachmentDialog } from 'common/ui/uploadAttachmentDialog/UploadAttachmentDialog';
import { useUploadAttachemnt } from 'common/hooks/useUploadAttachment';

import { useStyles } from './Attachments.styles';
import { AttachmentsProps } from './Attachments.types';

export const Attachments = ({ control }: AttachmentsProps) => {
  const styles = useStyles();
  const setDialog = useDialogDispatch();
  const { openConfirmation, closeConfirmation } = useConfirmationDialog({
    confirmButtonText: 'Delete',
    title: 'Delete attachment',
    message: 'Are you sure you want to delete attachment?',
  });

  const {
    field: { onChange, value },
  } = useController({
    name: 'attachments',
    control,
  });

  const { upload } = useUploadAttachemnt(value, onChange);

  const closeAttachmentForm = useCallback(() => {
    setDialog({ open: false });
  }, [setDialog]);

  const onDelete = useCallback(
    (id: number) => {
      onChange(value.filter((attachment) => attachment.id !== id));
      closeConfirmation();
    },
    [closeConfirmation, onChange, value],
  );

  const openAttachmentForm = useCallback(() => {
    setDialog({
      open: true,
      variant: 'basic',
      title: 'Upload Attachment',
      disableBackdropClick: true,
      dialogContent: (
        <UploadAttachmentDialog uploadAttachment={upload} onClose={closeAttachmentForm} limit={10 - value.length} />
      ),
      onClose: closeAttachmentForm,
    });
  }, [closeAttachmentForm, setDialog, upload, value.length]);

  return (
    <div className={styles.attachmentBox}>
      <Button buttonType="outlined" onClick={openAttachmentForm} disabled={value.length >= 10}>
        Upload files
      </Button>
      <Typography className={styles.fileTypes}>
        {value.length >= 10 ? 'File limit reached' : '*File types accepted: DOCX, DOC, PDF, JPEG, PNG'}
      </Typography>

      {!!value.length &&
        value.map((attachment) => (
          <div className={styles.list} style={{ gap: 8 }}>
            <AttachmentItem name={attachment.name} onDelete={() => openConfirmation(() => onDelete(attachment.id))} />

            {!!attachment.description && attachment.description !== '' && (
              <div className={styles.description}>
                <Typography className={styles.descriptionLabel}>Description</Typography>
                <Typography variant="subtitle1" color="secondary">
                  {attachment.description}
                </Typography>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};
