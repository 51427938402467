import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-fetching-library';
import moment from 'moment';
import { useAlert } from '@chhjit/react-components';

import { rudderanalytics } from 'common/utils/rudderanalytics';
import { useFormState } from 'common/hooks/useFormState/useFormState';
import { createAccountAddressAction, createAppointmentAction } from 'common/api/actions/account/accountActions';
import { useFormDispatch } from 'common/hooks/useFormDispatch/useFormDispatch';
import { setBookingDoneAndContact } from 'common/context/form/formActionCreators/formActionCreators';
import { junkRemovalRoutes } from 'common/routing/AppRoute';
import { ProcessingRequest } from 'common/app/processingRequest/ProcessingRequest';
import { checkAvailabilityAction } from 'common/api/actions/booking/bookingActions';
import { CategoryIdEnum } from 'common/api/types';
import { DateFormatForAPI } from 'common/utils/dateTime';
import { isSameSchedule } from 'common/utils/form';
import { useJunkRemovalDispatchContext, useJunkRemovalStateContext } from 'common/hooks/useJunkRemoval/useJunkRemoval';
import { junkRemovalActions } from 'common/context/junkRemoval/JunkRemovalContextSlice';
import { useFeatureFlags } from 'common/hooks/use-feature-flags/use-feature-flags';
import { uploadAttachmentAction } from 'common/api/actions/attachments/AttachmentsActions';

export const JunkRemovalProcessingRequest = () => {
  const history = useHistory();

  const { locationId, zoneId, accountId, token, partnerData } = useFormState();
  const { junkRemoval } = useJunkRemovalStateContext();

  const { junkRemovalDispatch } = useJunkRemovalDispatchContext();
  const dispatch = useFormDispatch();

  const { mutate: createAddressMutate, abort: abortCreateAddress } = useMutation(createAccountAddressAction);
  const { mutate: createAppointmentMutate, abort: abortCreateAppointment } = useMutation(createAppointmentAction);
  const { mutate: checkAvailability, abort: abortCheckAbailability } = useMutation(checkAvailabilityAction);
  const { mutate: uploadAttachmentMutate } = useMutation(uploadAttachmentAction);

  const { toggles } = useFeatureFlags();
  const { showAlert } = useAlert();

  const checkAgainAvailability = useCallback(async () => {
    abortCheckAbailability();

    if (!junkRemoval?.step1 || !junkRemoval?.step3 || locationId === undefined || zoneId === undefined) return;

    const { payload } = await checkAvailability({
      locationId,
      postal: junkRemoval.step1.fullAddress.postal,
      category_id: CategoryIdEnum.junk,
      view_date: moment(junkRemoval.step3.date).format(DateFormatForAPI),
      zone_id: zoneId,
    });

    if (!payload) return;

    const availability = payload.availability?.find((item) => {
      if (!junkRemoval.step3?.schedule) {
        return false;
      }

      return isSameSchedule({ ...item.schedule, resourceId: item.resource.id }, junkRemoval.step3.schedule);
    });

    if (!availability) {
      showAlert('The selected time is not available, please select another time.', {
        variant: 'error',
      });

      junkRemovalDispatch(junkRemovalActions.resetStep({ step: 'step3' }));

      history.push(junkRemovalRoutes.step3);

      return;
    }

    // return resourceId from new fetch, cause it sometimes change during booking
    return availability.resource.id;
  }, [
    locationId,
    zoneId,
    junkRemoval,
    history,
    checkAvailability,
    abortCheckAbailability,
    junkRemovalDispatch,
    showAlert,
  ]);

  const createAppointment = useCallback(async () => {
    if (
      !junkRemoval?.step1 ||
      !junkRemoval?.step2 ||
      !junkRemoval?.step3 ||
      !junkRemoval?.step4 ||
      !token ||
      !locationId ||
      !accountId
    ) {
      return;
    }

    const resourceId = await checkAgainAvailability();

    abortCreateAddress();
    const { payload } = await createAddressMutate({
      locationId,
      accountId,
      token,
      fullAddress: junkRemoval.step1.fullAddress,
    });

    if (!payload?.addresses) return;

    const originId = payload.addresses[0].id;

    abortCreateAppointment();

    /**
     * about resourceId:
     * because availability should be checked once again just before creatingAppointment
     * (because during booking some other user can reserve term)
     * then sometimes during second check it returns new resourceID
     * (ex. when user change address, then resourceId will be also changed)
     * so if it exist use new one, if not use provided before from step3 junk removal
     */
    const { payload: appointmentPayload } = await createAppointmentMutate({
      locationId,
      token,
      start_date: junkRemoval.step3.schedule.start,
      end_date: junkRemoval.step3.schedule.end,
      category: { id: CategoryIdEnum.junk },
      account: { id: accountId },
      origin: { id: originId },
      create_schedule: true,
      resource: { id: resourceId !== undefined ? resourceId : junkRemoval.step3.schedule.resourceId },
      notes: {
        special_instructions: junkRemoval.step2.specialInstructions,
        type_of_junk: `${junkRemoval.step4.title}${
          junkRemoval.step2.additionalNotes ? `. \nAdditional notes: ${junkRemoval.step2.additionalNotes}` : ''
        }`,
        location_of_junk: junkRemoval.step2.locationOfJunk || '',
      },
      purchase_order: junkRemoval.step2.orderNumber,
      attachments:
        toggles.PartnerPortal_AddAttachments && junkRemoval.step2.attachments?.length
          ? { urls: junkRemoval.step2.attachments.map(({ url }) => url) }
          : undefined,
      ...(toggles.PartnerManagement_DefaultReferralSource &&
        partnerData?.source_id && {
          source: {
            id: partnerData.source_id,
          },
        }),
      ...(partnerData?.subpartnerId !== undefined && { partner: { id: partnerData.subpartnerId } }),
    });

    if (appointmentPayload?.meta.status.code === 200 && appointmentPayload?.appointments) {
      const appointment = appointmentPayload?.appointments[0];

      const segmentTraits = {
        appointmentType: appointment.type,
        startDate: appointment.start_date,
        endDate: appointment.end_date,
        appointmentId: appointment.id,
        accountId: appointment.account.id,
        categoryId: appointment.category.id,
        locationId: appointment.location.id,
      };

      rudderanalytics.track('Booked Appointment', segmentTraits);

      dispatch(
        setBookingDoneAndContact({
          type: 'email',
          contact: junkRemoval.step2.email,
        }),
      );
      history.push(junkRemovalRoutes.alldone);
    }
  }, [
    junkRemoval.step1,
    junkRemoval.step2,
    junkRemoval.step3,
    junkRemoval.step4,
    token,
    locationId,
    accountId,
    checkAgainAvailability,
    abortCreateAddress,
    createAddressMutate,
    abortCreateAppointment,
    createAppointmentMutate,
    toggles.PartnerManagement_DefaultReferralSource,
    partnerData?.source_id,
    partnerData?.subpartnerId,
    dispatch,
    history,
    uploadAttachmentMutate,
    showAlert,
  ]);

  return <ProcessingRequest onProcess={() => createAppointment()} />;
};
