import { makeStyles } from '@material-ui/core';
import { colors } from '@chhjit/react-components';

export const useStyles = makeStyles({
  attachmentBox: {
    borderTop: `1px solid ${colors.grey30}`,
    marginTop: 8,
    paddingTop: 32,
  },
  fileTypes: {
    fontSize: 12,
    color: colors.grey140,
    marginTop: 4,
    fontWeight: 500,
  },
  fileBeLost: {
    color: colors.functionals.alert,
    fontSize: 12,
    marginTop: 4,
    fontWeight: 500,
  },
  list: {
    marginTop: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  description: {
    border: `1px solid ${colors.grey20}`,
    borderRadius: 4,
    backgroundColor: colors.basic.white,
    padding: '6px 12px',
  },
  descriptionLabel: {
    fontSize: 12,
    color: colors.grey140,
  },
});
