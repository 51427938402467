import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Typography, FormControl, Collapse } from '@material-ui/core';
import { Checkbox, Button, validateEmail, validatePhone, isUSAPostal, isCanadaPostal } from '@chhjit/react-components';
import { useForm, Controller } from 'react-hook-form';

import { ButtonsGroup } from 'common/ui/buttonsGroup/ButtonsGroup';
import { TipBox } from 'common/ui/tipBox/TipBox';
import { formatPhoneForApi } from 'common/utils/form';
import { useFormState } from 'common/hooks/useFormState/useFormState';
import { CorporateCodeModalContainer } from 'common/ui/corporateCodeModal/CorporateCodeModalContainer';
import { CategoryIdEnum } from 'common/api/types';
import { useFeatureFlags } from 'common/hooks/use-feature-flags/use-feature-flags';

import { ContactInformationInput } from './contactInformationInput/ContactInformationInput';
import { useStyles } from './ContactInformationForm.styles';
import { ContactInformationFormValues, ContactInformationFormProps } from './ContactInformationForm.types';
import { Attachments } from './attachments/Attachments';

export const ContactInformationForm = ({
  onBackClick,
  defaultValues,
  loading,
  title,
  tipBoxData,
  hasPrefilledData,
  onSubmit,
  categoryId,
}: ContactInformationFormProps) => {
  const styles = useStyles();
  const partnersSectionRef = useRef<HTMLDivElement | null>(null);
  const { isPartner, partnerData } = useFormState();
  const [isCorporateCodeModalOpen, setCorporateCodeModalOpen] = useState(false);
  const {
    handleSubmit,
    formState: { isValid, isDirty },
    trigger,
    control,
    watch,
    setValue,
    register,
  } = useForm<ContactInformationFormValues>({
    defaultValues: {
      ...defaultValues,
      hasCorporateCode: isPartner,
    },
    mode: 'onBlur',
  });

  const isHasCorporateCodeChecked = watch('hasCorporateCode');

  const scrollOnNewFieldsAppear = () => {
    setTimeout(() => {
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
      // timeout=201, because it must be runned after <Collapse/> animation
    }, 201);
  };

  const onSubmitCallback = useCallback(
    (values: ContactInformationFormValues) => {
      const correctedValues: ContactInformationFormValues = {
        ...values,
        phone: formatPhoneForApi(values?.phone || ''), // phone optional in ver B
        companyName: values.companyName || '',
        orderNumber: values.orderNumber || '',
        specialInstructions: values.specialInstructions || '',
        additionalNotes: values.additionalNotes || '',
      };
      onSubmit(correctedValues, isDirty || isValid);
    },
    [isDirty, onSubmit, isValid],
  );

  const handleCheckboxChange = useCallback(
    (value: boolean) => {
      if (!isPartner) {
        setCorporateCodeModalOpen(true);
        return;
      }

      if (value) {
        scrollOnNewFieldsAppear();
      }
    },
    [isPartner],
  );

  useEffect(() => {
    if (hasPrefilledData) trigger();
  }, [trigger, hasPrefilledData]);

  useEffect(() => {
    if (isPartner && !isHasCorporateCodeChecked) {
      setValue('hasCorporateCode', true);
      scrollOnNewFieldsAppear();
    }
  }, [setValue, isPartner, isHasCorporateCodeChecked]);

  const { toggles } = useFeatureFlags();

  useEffect(() => {
    register('attachments');
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitCallback)} className={styles.form}>
        <div className={styles.content}>
          <Typography variant="h4" className={styles.margin}>
            {title}
          </Typography>

          <Typography variant="body1" className={styles.margin}>
            Please provide your contact information so we can get in touch on the day of your service. We will also
            follow up to confirm availability and lock-in your appointment!
            <br />
            <br />
            <small>
              *We respect your privacy and will never sell, rent, lease or give away your information (name, address,
              email, etc.) to any third party.
            </small>
          </Typography>

          <ContactInformationInput
            name="firstName"
            label={isPartner ? 'Contact first name' : 'First name'}
            rules={{
              required: '*required',
              maxLength: { value: 50, message: '*max 50 characters' },
            }}
            control={control}
            required
            hasPrefilledData={hasPrefilledData}
          />

          <ContactInformationInput
            name="lastName"
            label={isPartner ? 'Contact last name' : 'Last name'}
            rules={{
              required: '*required',
              maxLength: { value: 50, message: '*max 50 characters' },
            }}
            control={control}
            hasPrefilledData={hasPrefilledData}
            required
          />

          <ContactInformationInput
            name="email"
            label="Email"
            rules={{
              required: '*required',
              validate: (value) =>
                validateEmail(value) || 'Please provide proper e-mail format, like: example@domain.com',
            }}
            control={control}
            hasPrefilledData={hasPrefilledData}
            required
          />

          {/* without phone input on junkRemoval (so when phone defaultValue is not provided) */}
          {defaultValues.phone !== undefined && (
            <ContactInformationInput
              name="phone"
              label="Contact phone number"
              mask="phone"
              rules={{
                required: '*required',
                validate: (value) => validatePhone(value) || 'Please provide proper phone number',
              }}
              control={control}
              hasPrefilledData={hasPrefilledData}
              required
            />
          )}

          <ContactInformationInput
            name="postal"
            label="Zip/postal code"
            rules={{
              required: '*required',
              validate: (value) =>
                isUSAPostal(value) || isCanadaPostal(value) || 'Please provide valid zip/postal code',
            }}
            control={control}
            required
          />

          {(!isPartner || (isPartner && partnerData?.shouldDisplayPartnersFields)) && (
            <FormControl ref={partnersSectionRef}>
              <Controller
                name="hasCorporateCode"
                control={control}
                render={({ field: { onChange, onBlur, value, ref, name } }) => (
                  <Checkbox
                    onBlur={onBlur}
                    onChange={(value) => {
                      onChange(value);
                      handleCheckboxChange(value);
                    }}
                    inputRef={ref}
                    value={value}
                    checked={value}
                    name={name}
                    disabled={isPartner}
                    variant="outline"
                    label="I have a corporate code"
                  />
                )}
              />
            </FormControl>
          )}

          {isPartner && partnerData?.shouldDisplayPartnersFields && (
            <Collapse timeout={200} in={isHasCorporateCodeChecked}>
              <div>
                <div className={styles.divider} />
                <ContactInformationInput name="companyName" label="Company name (if applicable)" control={control} />
                <ContactInformationInput
                  name="orderNumber"
                  label="Purchase order number (if applicable)"
                  control={control}
                />
                <Box mb={1} />
                <ContactInformationInput
                  name="specialInstructions"
                  label="Special instructions"
                  rows={2}
                  control={control}
                />
                <Box mb={1} />

                {(!toggles.PartnerPortal_HideTypeOfItemsForMoving || categoryId !== CategoryIdEnum.move) && (
                  <ContactInformationInput name="additionalNotes" label="Type of Items" rows={2} control={control} />
                )}

                {categoryId === CategoryIdEnum.junk && (
                  <>
                    <Box mb={1} />
                    <ContactInformationInput
                      name="locationOfJunk"
                      label="Location of Items"
                      rows={2}
                      control={control}
                    />
                  </>
                )}

                {toggles.PartnerPortal_AddAttachments && categoryId === CategoryIdEnum.junk && (
                  <Attachments control={control} />
                )}
              </div>
            </Collapse>
          )}
        </div>

        <Box mt="auto">
          <TipBox iconName={tipBoxData.icon} fullWidth className={styles.tipBox}>
            {tipBoxData.text}
          </TipBox>

          <ButtonsGroup>
            <Button onClick={onBackClick} fullWidth size="large" buttonType="outlined">
              Back
            </Button>
            <Button disabled={!isValid} isLoading={loading} type="submit" fullWidth size="large" buttonType="twoTone">
              Next
            </Button>
          </ButtonsGroup>
        </Box>
      </form>

      <CorporateCodeModalContainer open={isCorporateCodeModalOpen} onClose={() => setCorporateCodeModalOpen(false)} />
    </>
  );
};
