import React, { useMemo } from 'react';
import { Typography, Grid, useTheme, useMediaQuery } from '@material-ui/core';

// import { CallMeNowModalContainer } from 'common/app/callMeNowModal/CallMeNowModalContainer';
import { AsideNavbar } from 'common/ui/asideNavbar/AsideNavbar';
import { Breadcrumbs } from 'common/ui/breadcrumbs/Breadcrumbs';
import { useScrollBehavior } from 'common/hooks/useScrollBehavior/useScrollBehavior';
import { useFormState } from 'common/hooks/useFormState/useFormState';
import { PARTNER_NAME } from 'common/utils/env-variables';

import { MainLayoutProps } from './MainLayout.types';
import { useStyles } from './MainLayout.styles';

const headerTextmap = (partnerName?: string) => ({
  junkRemoval: partnerName === PARTNER_NAME ? 'Downsizing support' : 'Junk removal',
  moving: 'Moving',
  labor: 'Labor',
});

export const MainLayout = ({ type, children }: MainLayoutProps) => {
  const styles = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { partnerData } = useFormState();

  useScrollBehavior();

  const headerText = useMemo(() => headerTextmap(partnerData?.partnerName)[type], [partnerData?.partnerName, type]);

  return (
    <div className={styles.wrapper} data-testid="main-layout">
      <div className={styles.headerWrapper}>
        <Breadcrumbs />
        <Typography variant="h2" className={styles.header}>
          {headerText}
        </Typography>
      </div>

      <Grid container className={styles.gridContainer}>
        {isMobile && (
          <Grid item>
            <AsideNavbar type={type} mobileVersionType="prevItems" />
          </Grid>
        )}

        <Grid item component="main" id="main" className={styles.section}>
          {children}
        </Grid>

        <Grid item component="aside" className={styles.aside}>
          <AsideNavbar type={type} mobileVersionType={isMobile ? 'nextItems' : undefined} />
        </Grid>
      </Grid>
    </div>

    // {/* TODO CALLMNOW: modal hidden for now */}
    // <div className={styles.wrapper}>
    // {MainLayut code here}
    // {/* <CallMeNowModalContainer /> */}
    // </div>
  );
};
